<template>
  <div>
    <div class="d-flex justify-space-between align-center mt-2">
      <h6 class="mb-0">Periodos</h6>
      <v-btn
        color="primary"
        @click="addPeriod"
        :small="$vuetify.breakpoint.mdAndDown"
        v-if="party.Period.length"
      >
        <v-icon left :small="$vuetify.breakpoint.mdAndDown"> mdi-plus </v-icon>
        período
      </v-btn>
    </div>
    <v-alert color="info" text v-if="!party.Period.length" class="mt-2 mb-0">
      <p class="text-18 font-weight-bold lh-1">
        Seu evento ainda não tem períodos definidos.
      </p>
      <p class="text-16 font-weight-bold mb-0">Quando usar períodos?</p>
      <p>
        Considere períodos caso seu evento seja realizado em múltiplos dias ou
        tenha várias sessões. Eles otimizam a organização, controlam o acesso
        dos participantes e asseguram a validade dos ingressos por etapa,
        evitando superlotações.
      </p>
      <p class="text-16 font-weight-bold mb-0">Benefícios dos períodos:</p>
      <ul>
        <li>
          Determine exatamente quais setores podem acessar determinados
          períodos.
        </li>
        <li>Validação única de ingressos por período, evitando fraudes.</li>
        <li>Flexibilidade para vincular atrações a períodos específicos.</li>
        <li>Visibilidade dos períodos na página do evento e no ingresso.</li>
      </ul>
      <v-btn class="mt-4" color="info" depressed @click="addPeriod">
        Criar período
      </v-btn>
    </v-alert>

    <v-timeline align-top dense v-else>
      <v-timeline-item
        v-for="(period, i) in periodsWithDates"
        :key="i"
        :hide-dot="period.type !== 'period'"
        :class="{ 'pb-1': period.type !== 'period' }"
        :color="!(period.TicketGroup || []).length ? 'error' : 'primary'"
      >
        <template v-slot:icon>
          <b class="text-18 white--text">{{ period.index + 1 }}</b>
        </template>
        <template v-if="period.type == 'period'">
          <div>
            <v-card outlined class="pa-4">
              <div class="d-flex justify-space-between align-cente">
                <div class="d-flex flex-column">
                  <h5 class="mb-0" :class="{ 'font-italic': !period.name }">
                    {{ period.name || `Período ${period.index + 1}` }}
                    <v-btn @click="edit(period)" icon small>
                      <v-icon small>mdi-pencil</v-icon>
                    </v-btn>
                  </h5>
                  <v-tooltip right v-if="(period.TicketGroup || []).length">
                    <template v-slot:activator="{ on, attrs }">
                      <div v-bind="attrs" v-on="on">
                        <v-chip label>
                          {{ period.TicketGroup.length }} Setor{{
                            period.TicketGroup.length != 1 ? "es" : ""
                          }}
                          <v-icon x-small right>mdi-information-outline</v-icon>
                        </v-chip>
                      </div>
                    </template>
                    <span>
                      {{ period.TicketGroup.map((tg) => tg.name).join(", ") }}
                    </span>
                  </v-tooltip>
                  <v-chip v-else color="error" label> Nenhum setor </v-chip>
                </div>
                <div class="d-flex">
                  <div class="d-flex flex-column gap-1">
                    <div class="d-flex align-center">
                      <v-icon small left>mdi-calendar-start</v-icon>
                      <span>
                        {{ period.startDate | date("DD/MM [às] HH:mm") }} até
                      </span>
                    </div>
                    <div class="d-flex align-center">
                      <v-icon small left>mdi-calendar-end</v-icon>
                      <span>
                        {{ period.endDate | date("DD/MM [às] HH:mm") }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <p class="mb-0">{{ period.description }}</p>
            </v-card>
          </div>
        </template>
        <template v-else>
          <v-divider class="mb-2" v-if="i"></v-divider>
          <span class="text-overline font-weight-bold">
            {{ period.date | date("DD [de] MMM") }}
          </span>
        </template>
      </v-timeline-item>
    </v-timeline>

    <period-modal
      :party="party"
      @success="$emit('refresh')"
      ref="periodModal"
    />
  </div>
</template>

<script>
import Avatar from "../../base/Avatar.vue";
import PeriodModal from "./modal/PeriodModal.vue";
import moment from "moment";
export default {
  components: { PeriodModal, Avatar },
  data: () => ({}),
  methods: {
    addPeriod() {
      this.$refs.periodModal.open();
    },
    edit(period) {
      this.$refs.periodModal.open(period);
    },
  },
  computed: {
    periodsWithDates() {
      const result = [];

      this.party.Period.sort((a, b) => {
        return moment(a.startDate).diff(moment(b.startDate));
      }).forEach((period, i) => {
        const lastResult = result[result.length - 1] || null;
        if (!lastResult)
          result.push({
            type: "date",
            date: moment(period.startDate).format("YYYY-MM-DD"),
          });
        else if (lastResult.type === "period") {
          const lastPeriodDate = moment(lastResult.startDate).format(
            "YYYY-MM-DD"
          );
          const currentPeriodDate = moment(period.startDate).format(
            "YYYY-MM-DD"
          );
          if (lastPeriodDate !== currentPeriodDate)
            result.push({ type: "date", date: period.startDate });
        }

        result.push({
          type: "period",
          index: i,
          ...period,
        });
      });
      return result;
    },
  },
  mounted() {},
  props: {
    party: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style></style>
