<template>
  <v-dialog v-model="dialog" max-width="600" @click:outside="close" scrollable>
    <v-card :loading="loading" rounded="lg">
      <v-card-title class="text-16 pb-1 justify-space-between">
        <span class="d-flex align-center gap-1 lh-1">
          Compra
          <base-copier type="chip" :value="paymentId" hide-icon>
            <template v-slot:default="{ copy, icon }">
              <v-chip
                @click="copy"
                x-small
                label
                class="text-overline font-weight-bold"
              >
                #{{ paymentId | shortId }}
                <v-icon right x-small>{{ icon }}</v-icon>
              </v-chip>
            </template>
          </base-copier>
        </span>
        <v-btn
          v-if="payment"
          x-small
          depressed
          rounded
          :loading="loading"
          @click="refreshTicketPayment"
          :color="PAYMENT.ticketStatus[payment?.status]?.color"
        >
          <v-icon left small>{{
            PAYMENT.ticketStatus[payment?.status]?.icon
          }}</v-icon>
          {{ PAYMENT.ticketStatus[payment?.status]?.text }}
        </v-btn>
        <v-skeleton-loader
          v-else
          x-small
          width="100"
          height="22"
          type="card"
          class="rounded-xl"
        />
        <!-- <v-chip small :color="payment.status.color">
          <v-icon left small>{{ payment.status.icon }}</v-icon>
          {{ payment.status.text }}
        </v-chip> -->
        <!-- <span> {{ payment.amount | currency }}</span> -->
      </v-card-title>
      <v-card-text v-if="error">
        <v-alert type="error" class="mb-0 mt-2">
          {{ error }}
        </v-alert>
      </v-card-text>
      <v-card-text v-else-if="payment" class="d-flex flex-column gap-y-3 pt-2">
        <v-card
          outlined
          class="d-flex justify-space-around flex-wrap gap-x-6 gap-y-3 pa-3"
        >
          <div class="text-center">
            <p class="mb-0 lh-1">Data da compra</p>
            <p class="mb-0 font-weight-bold">
              {{ payment.createdAt | date("DD/MM/YYYY HH:mm") }}
            </p>
          </div>
          <div
            class="text-center"
            v-if="payment.status === 'pending' && payment.validity"
          >
            <p class="mb-0 lh-1">Vencimento</p>
            <p class="mb-0 font-weight-bold">
              {{ payment.validity | date("DD/MM/YYYY HH:mm") }}
            </p>
          </div>
          <div class="text-center">
            <p class="mb-0 lh-1">Meio de pagamento</p>
            <p class="mb-0 font-weight-bold">
              {{ PAYMENT.paymentMethod[payment.paymentMethod]?.text }}
            </p>
          </div>
          <div class="text-center">
            <p class="mb-0 lh-1">Forma de pagamento</p>
            <p class="mb-0 font-weight-bold">
              {{ PAYMENT.paymentType[payment.paymentType]?.text }}
            </p>
          </div>
          <div class="text-center">
            <p class="mb-0 lh-1">{{ seller.type }}</p>
            <p class="mb-0 font-weight-bold">
              {{ seller.name }}
            </p>
          </div>
          <div class="text-center">
            <p class="mb-0 lh-1">Valor Pago</p>
            <p class="mb-0 font-weight-bold">
              {{ (payment.amount - payment.clientFee) | currency }}
              <span v-if="payment.clientFee > 0" class="text-caption">
                + {{ payment.clientFee | currency }}
              </span>
            </p>
          </div>
          <div class="text-center">
            <p class="mb-0 lh-1">Valor liquido</p>
            <p class="mb-0 font-weight-bold">
              {{ (payment.amount - payment.platformFee) | currency }}
            </p>
          </div>
        </v-card>

        <v-card outlined class="pa-3">
          <h6>Ingressos</h6>
          <v-data-table
            :headers="headers"
            :expanded.sync="expanded"
            :items="payment.Ticket"
            :items-per-page="payment.Ticket.length"
            :hide-default-footer="true"
            item-key="id"
            selectable-key="id"
            order-by="TicketBlock.TicketGroup.name"
            single-expand
            show-expand
          >
            <template #item.id="{ item }">
              <span class="text-overline lh-1">#{{ item.id | shortId }} </span>
            </template>
            <template #item.action="{ item }">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-if="
                      hasPermission(128) &&
                      !PAYMENT.status_db_groups.canceled.includes(
                        payment.status.value
                      )
                    "
                    @click="transfer(item)"
                    icon
                    v-on="on"
                    :disabled="loading"
                  >
                    <v-icon> mdi-account-arrow-right </v-icon>
                  </v-btn>
                </template>
                <span>Transferir</span>
              </v-tooltip>
            </template>
            <template #item.data-table-expand="{ item, on, expand }">
              <v-btn
                v-if="item.TicketTransfer.length"
                icon
                v-on="on"
                @click="expand"
              >
                <v-icon> mdi-swap-horizontal </v-icon>
              </v-btn>
            </template>
            <template #expanded-item="{ item, headers }">
              <td class="" :colspan="headers.length">
                <GuestTicketTransfer :ticketTransfer="item.TicketTransfer" />
              </td>
            </template>
          </v-data-table>
        </v-card>

        <v-row dense class="mx-0">
          <template v-if="payment?.status?.value === 'succeeded'">
            <v-col v-if="hasPermission(64)" cols="12" sm="" class="pt-0 pb-2">
              <v-btn
                color="info"
                @click="resend(payment.tickets[0])"
                :loading="loading"
                :disabled="ticketResent"
                small
                block
              >
                <v-icon small left>mdi-invoice-send</v-icon>
                <span v-if="!ticketResent">
                  Reenviar ingresso{{
                    (payment?.tickets || []).length > 1 ? "s" : ""
                  }}
                </span>
                <span v-else>
                  Ingresso{{
                    payment.tickets.length > 1 ? "s" : ""
                  }}
                  reenviado{{ payment.tickets.length > 1 ? "s" : "" }}</span
                >
              </v-btn>
            </v-col>

            <v-col v-if="hasPermission(128)" cols="12" sm="" class="pt-0 pb-2">
              <v-btn
                color="warning"
                @click="refund"
                :disabled="loading"
                small
                block
              >
                <v-icon small left>mdi-cash-refund</v-icon> Reembolsar
              </v-btn>
            </v-col>
          </template>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn @click="close" small text block :disabled="loading">
          Fechar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import PAYMENT from "@/utils/payment";
import TICKET from "@/services/admin/ticket";
import PARTY from "@/services/admin/party";

import GuestTicketTransfer from "./GuestTicketTransferDetails.vue";

export default {
  props: {
    party: {
      type: Object,
      required: true,
    },
  },
  components: { GuestTicketTransfer },
  data: () => ({
    dialog: false,
    loading: false,
    ticketResent: false,
    payment: null,
    paymentId: null,
    expanded: [],
    PAYMENT,
    error: null,
    headers: [
      { text: "ID", value: "id", sortable: false },
      {
        text: "Setor",
        value: "TicketBlock.TicketGroup.name",
      },
      { text: "Lote", value: "TicketBlock.name" },
      { text: "", value: "action", align: "end", sortable: false },
      { text: "", value: "data-table-expand", align: "end", sortable: false },
    ],
  }),
  methods: {
    async getPaymentDetails() {
      try {
        this.loading = true;
        const response = await PARTY.reports.paymentDetail(
          this.party.organizationId,
          this.party.id,
          this.paymentId
        );
        this.payment = response.payment;
      } catch (e) {
        this.error = e.message || "Erro ao buscar detalhes da compra";
      } finally {
        this.loading = false;
      }
    },
    expandTicket(ticket) {
      this.expanded = this.expanded[0] === ticket.id ? [] : [ticket.id];
    },
    transfer(ticket) {
      this.$root.$emit("transfer-ticket", { ...ticket, Payment: this.payment });
    },
    refund() {
      const ticket = this.payment.tickets[0];
      this.$root.$emit("refund-ticket", { ...ticket, Payment: this.payment });
    },
    async resend(ticket) {
      if (!this.hasPermission(64)) return;
      try {
        this.loading = true;
        const response = await TICKET.resend(
          this.selectedOrganization.id,
          this.$route.params.partyId,
          ticket.id
        );
        this.ticketResent = true;
        console.log("Payment refresh", response);
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    },
    async refreshTicketPayment() {
      if (!this.hasPermission(0)) return;
      try {
        this.loading = true;
        const response = await TICKET.updateTicketPayment(
          this.selectedOrganization.id,
          this.$route.params.partyId,
          this.payment.tickets[0].id
        );
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    },
    open(payment) {
      if (this.dialog) return;
      this.dialog = true;
      this.payment = null;
      this.paymentId = payment.id;
      this.getPaymentDetails();
    },
    close() {
      this.dialog = false;
      this.loading = false;
      this.expanded = [];
      this.ticketResent = false;
      this.payment = null;
      this.paymentId = null;
      this.error = null;
      // this.$emit("refresh");
    },
  },
  computed: {
    ...mapGetters("auth", ["hasPermission"]),
    ...mapGetters("organization", ["selectedOrganization"]),
    seller() {
      if (this.payment.PosSession)
        return {
          type: this.payment.PosSession.partyId ? "Bilheteria" : "PDV",
          name:
            this.payment.PosSession?.name ||
            this.payment.PosSession?.Address?.name,
        };

      const [ticket] = this.payment?.Ticket || [];
      if (ticket?.Seller) return { type: "Promoter", name: ticket.Seller.name };

      return { type: "Online", name: "Online" };
    },
  },
  mounted() {
    this.$root.$on("payment-details", this.open);
  },
};
</script>

<style></style>
