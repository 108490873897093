<template>
  <v-dialog
    v-model="dialog"
    width="600"
    :persistent="loading"
    scrollable
    @click:outside="close"
    eager
  >
    <v-card class="h-full d-flex flex-column" style="max-height: 90dvh">
      <v-card-title class="pb-0 pt-3 px-6">
        <span class="text-16 lh-1"> Adicionar bilheteria </span>
      </v-card-title>
      <v-card-text class="pa-0 ma-0">
        <vue-perfect-scrollbar
          :settings="{ suppressScrollX: true, wheelPropagation: false }"
          style="height: 100%"
        >
          <v-stepper v-model="step" class="elevation-0">
            <v-stepper-header class="elevation-0 mb-2" style="height: 50px">
              <v-stepper-step :complete="step > 1" step="1">
                Opções
              </v-stepper-step>
              <v-divider></v-divider>
              <v-stepper-step :complete="step > 2" step="2">
                Leitor
              </v-stepper-step>
            </v-stepper-header>

            <v-stepper-items>
              <v-stepper-content step="1" class="pt-0">
                <v-form v-model="valid">
                  <v-row class="">
                    <v-col cols="12">
                      <v-btn-toggle
                        v-model="ticketOffice.name.type"
                        mandatory
                        class="d-flex"
                      >
                        <v-btn value="custom" small class="flex-grow-1">
                          Personalizado
                        </v-btn>
                        <v-btn value="sequential" small class="flex-grow-1">
                          Sequencial
                        </v-btn>
                      </v-btn-toggle>
                    </v-col>
                    <v-col v-if="ticketOffice.name.type == 'custom'" cols="12">
                      <v-alert text type="info" class="mb-0">
                        Após a leitura do QRCode, você poderá informar o nome da
                        bilheteria.
                      </v-alert>
                    </v-col>
                    <template
                      v-else-if="ticketOffice.name.type == 'sequential'"
                    >
                      <v-col cols="4" md="3">
                        <v-text-field
                          v-model="ticketOffice.name.start"
                          label="Inicia em"
                          outlined
                          dense
                          type="number"
                          :rules="[
                            (v) => !!v || 'Campo obrigatório',
                            (v) => v >= 1 || 'Valor mínimo: 1',
                          ]"
                        ></v-text-field>
                      </v-col>

                      <v-col cols="8" md="9">
                        <v-text-field
                          v-model="ticketOffice.name.prefix"
                          label="Prefixo"
                          outlined
                          dense
                          :rules="[(v) => !!v || 'Campo obrigatório']"
                          :hint="`Ex: ${ticketOffice.name.prefix} - 01`"
                        ></v-text-field>
                      </v-col>
                    </template>
                    <v-col cols="12">
                      <h6 class="mb-0">Formas de pagamento</h6>

                      <div class="d-flex gap-4 flex-wrap">
                        <v-checkbox
                          v-model="ticketOffice.allowPaymentMethods"
                          label="Dinheiro"
                          value="money"
                          class="mt-0"
                          hide-details
                        />
                        <v-checkbox
                          v-model="ticketOffice.allowPaymentMethods"
                          label="PIX"
                          value="pix"
                          class="mt-0"
                          hide-details
                        />
                        <v-checkbox
                          v-model="ticketOffice.allowPaymentMethods"
                          label="Cartão de Crédito"
                          value="credit"
                          class="mt-0"
                          hide-details
                        />
                        <v-checkbox
                          v-model="ticketOffice.allowPaymentMethods"
                          label="Cartão de Débito"
                          value="debit"
                          class="mt-0"
                          hide-details
                        />
                      </div>
                    </v-col>
                  </v-row>
                  <v-divider class="my-2"></v-divider>
                  <h6>Pode vender</h6>
                  <div v-for="(tg, i) in ticketGroups" :key="tg.id">
                    <v-divider v-if="i !== 0" class="my-2"></v-divider>
                    <div class="d-flex justify-space-between px-2">
                      <h6 class="text-14">{{ tg.name }}</h6>
                      <v-btn small text @click.stop="toggleAll(tg.TicketBlock)">
                        {{
                          tg.TicketBlock.some(
                            (tb) =>
                              !(ticketOffice.ticketBlocks || []).includes(tb.id)
                          )
                            ? "Marcar todos"
                            : "Desmarcar todos"
                        }}
                      </v-btn>
                    </div>

                    <v-row class="mx-2" no-gutters>
                      <v-col
                        cols="12"
                        sm="6"
                        v-for="tb in tg.TicketBlock"
                        :key="tb.id"
                      >
                        <v-checkbox
                          v-model="ticketOffice.ticketBlocks"
                          :value="tb.id"
                          :label="tb.name"
                          dense
                          multiple
                          hide-details
                          class="mt-0 pt-0"
                        />
                      </v-col>
                    </v-row>
                  </div>
                </v-form>
              </v-stepper-content>
              <v-stepper-content step="2" class="pt-0 px-4 pb-0">
                <div style="position: relative">
                  <scanner
                    @scan="onScan"
                    :disallowMethods="['nfc']"
                    :paused="step !== 2 || loading"
                    class="rounded-lg"
                  />
                </div>
                <v-alert
                  v-if="!!error"
                  outlined
                  text
                  color="error"
                  class="mt-2"
                >
                  {{ error }}
                </v-alert>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </vue-perfect-scrollbar>
      </v-card-text>

      <v-card-actions>
        <template v-if="step === 1">
          <v-btn class="ml-1" text :disabled="loading" @click="close">
            Cancelar
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            class="ml-1"
            color="primary"
            :loading="loading"
            :disabled="!validData"
            @click="step = 2"
          >
            Próximo
          </v-btn>
        </template>
        <template v-if="step === 2">
          <span>
            {{ currentName || "Nome Personalizado" }}
          </span>
          <v-spacer></v-spacer>
          <v-btn v-if="loading" class="ml-1" @click="loading = false">
            Finalizar
          </v-btn>
          <v-btn
            class="ml-1"
            color="primary"
            :loading="loading"
            :disabled="loading"
            @click="close"
          >
            Finalizar
          </v-btn>
        </template>
      </v-card-actions>
    </v-card>
    <ticket-office-adopt-modal
      @success="success"
      :party="party"
      ref="adoptModal"
    />
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import SELLER from "@/services/admin/party/seller";
import Scanner from "../../../global/scanner/Scanner.vue";
import TicketOfficeAdoptModal from "./TicketOfficeAdoptModal.vue";

const defaultTicketOffice = () => ({
  name: {
    type: "custom",
    prefix: "",
    start: 1,
    end: 1,
  },
  allowPaymentMethods: ["money", "pix", "credit", "debit"],
  ticketBlocks: [],
});

export default {
  components: { Scanner, TicketOfficeAdoptModal },
  data: () => ({
    dialog: false,
    error: false,
    valid: false,
    loading: false,
    step: 1,
    valid: false,
    count: 0,
    ticketOffice: defaultTicketOffice(),
  }),

  methods: {
    async save() {
      try {
        this.loading = true;
        this.error = false;

        await SELLER.update(
          this.party.organizationId,
          this.party.id,
          this.seller.id,
          {
            ticketBlocks: this.ticketOffice.ticketBlocks,
            type: this.seller.type,
          }
        );

        this.$emit("success");
        this.loading = false;
        this.close();
      } catch (e) {
        this.loading = false;
        this.error = e.message || e;
      }
    },
    success() {
      this.loading = false;
      this.error = false;
      this.count++;
    },
    onScan({ value }) {
      const hostname = window.location.hostname;
      if (!value.includes(hostname)) return alert("Código inválido");
      const regex =
        /code=([0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}:[0-9a-fA-F]{32})/;

      const match = value.match(regex);
      if (match && match[1]) {
        const code = match[1];
        this.useCode(code);
      } else alert("Código não encontrado ou inválido.");
    },
    useCode(code) {
      this.loading = true;
      this.error = false;
      this.step = 2;
      const name =
        this.ticketOffice.name.type == "sequential" && this.currentName;
      const data = {
        code,
        ticketOffice: {
          ticketBlocks: this.ticketOffice.ticketBlocks,
          allowPaymentMethods: this.ticketOffice.allowPaymentMethods,
          name: name || null,
        },
      };
      console.log("useCode", data);
      this.$root.$emit("ticket-office-adopt-modal", data);
    },
    open() {
      this.loading = false;
      this.error = false;
      this.step = 1;
      this.count = 0;
      this.valid = false;
      this.ticketOffice = defaultTicketOffice();
      this.dialog = true;
    },
    close() {
      if (this.loading) return;
      this.loading = false;
      this.error = false;
      this.step = 1;
      this.count = 0;
      this.valid = false;
      this.ticketOffice = defaultTicketOffice();
      this.dialog = false;
    },
    toggleAll(ticketBlocks) {
      const ticketOfficeTicketBlocks = this.ticketOffice.ticketBlocks || [];

      ticketBlocks = ticketBlocks.map((p) => p.id);
      const hasAll = ticketBlocks.every((p) =>
        ticketOfficeTicketBlocks.includes(p)
      );

      if (hasAll) {
        this.ticketOffice.ticketBlocks = ticketOfficeTicketBlocks.filter(
          (p) => !ticketBlocks.includes(p)
        );
      } else {
        this.ticketOffice.ticketBlocks = [
          ...new Set(ticketOfficeTicketBlocks.concat(ticketBlocks)),
        ];
      }
    },
  },
  computed: {
    ...mapGetters("organization", ["selectedOrganization"]),
    currentName() {
      if (this.ticketOffice.name.type == "custom") return null;
      return `${this.ticketOffice.name.prefix} - ${
        parseInt(this.ticketOffice.name.start) + this.count
      }`;
    },
    validData() {
      if (!this.ticketOffice.ticketBlocks.length) return false;
      if (!this.ticketOffice.allowPaymentMethods.length) return false;
      if (
        this.ticketOffice.name.type == "sequential" &&
        !this.ticketOffice.name.prefix
      )
        return false;
      if (
        this.ticketOffice.name.type == "sequential" &&
        !this.ticketOffice.name.start
      )
        return false;

      return true;
    },
  },
  mounted() {
    this.$root.$on("ticket-office-modal", this.open);
  },
  watch: {},
  props: {
    party: {
      type: Object,
      required: true,
    },
    ticketGroups: {
      type: Array | Boolean,
      default: false,
    },
  },
};
</script>

<style></style>
