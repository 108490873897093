import { description } from "platform";
import store from "../../store";
import partyStatus from "../../utils/partyStatus";
const tabs = [
  {
    type: "party.management",
    id: "tickets",
    name: "Ingressos",
    permission: 16,
    icon: "mdi-ticket",
    description: (party) => `Gerenciar lotes e setores do evento ${party.name}`,
    keywords: ["lote", "setor", "convite", "ingresso"],
  },
  {
    type: "party.management",
    id: "guests",
    name: "Convidados",
    permission: 64,
    icon: "mdi-account-multiple",
    description: (party) => `Gerenciar convidados do evento ${party.name}`,
    keywords: [
      "convidado",
      "convidados",
      "lista",
      "lista de convidados",
      "reembolsar",
      "reembolso",
    ],
  },
  {
    type: "party.management",
    id: "reports",
    name: "Relatórios",
    permission: 512,
    icon: "mdi-chart-bar",
  },
  {
    type: "party.management",
    id: "analytics",
    name: "Analytics",
    permission: 512,
    icon: "mdi-chart-line",
  },
  {
    type: "party.management",
    id: "offlinePayments",
    name: "Recebimentos Externos",
    permission: 8192,
    icon: "mdi-tray-arrow-down",
    description: (party) =>
      `Gerenciar recebimentos externos do evento ${party.name}`,
    keywords: [
      "recebimento externo",
      "recebimentos externos",
      "debito de promotores",
    ],
  },
  {
    type: "party.management",
    id: "sellers",
    name: "PDV & Vendedores",
    permission: [2, 16],
    icon: "mdi-account-group",
    description: (party) =>
      `Gerenciar os PDVs, vendedores e perfis na página do evento ${party.name}`,
  },
  {
    type: "party.management",
    id: "refound",
    name: "Reembolsos",
    component: "TicketRefunds",
    permission: 128,
    icon: "mdi-cash-refund",
    description: (party) =>
      `Ver solicitações de reembolso do evento ${party.name}`,
  },
  {
    type: "party.management",
    id: "courtesy",
    name: "Cortesias",
    permission: 524288,
    icon: "mdi-account-star",
    description: (party) => `Emitir cortesias para o evento ${party.name}`,
  },
  {
    type: "party.details",
    id: "party.details",
    name: "Detalhes",
    permission: 2,
    icon: "mdi-information",
    description: (party) =>
      `Alterar nome, data, local e privacidade do evento ${party.name} `,
    keywords: [
      "editar nome",
      "alterar nome",
      "editar data",
      "alterar data",
      "editar local",
      "alterar local",
      "editar endereço",
      "alterar endereço",
      "editar horário",
      "alterar horário",
      "editar detalhes",
      "alterar detalhes",
      "editar privacidade",
      "alterar privacidade",
    ],
  },
  {
    type: "party.details",
    id: "policies",
    name: "Políticas",
    permission: 2,
    icon: "mdi-security",
    description: (party) =>
      `Gerenciar regras de reembolso, faixa etária e normas da portaria do evento ${party.name}`,
    keywords: ["faixa etária", "idade", "política de reembolso"],
  },
  {
    type: "party.details",
    id: "attractions",
    name: "Atrações",
    permission: 2,
    icon: "mdi-music",
  },
  {
    type: "party.details",
    id: "gallery",
    name: "Galeria",
    permission: 2,
    icon: "mdi-image",
  },
  {
    type: "party.details",
    id: "period",
    name: "Periodos",
    permission: 2,
    icon: "mdi-calendar-multiple",
  },
  {
    type: "party.details",
    id: "faq",
    name: "FAQ",
    permission: 2,
    icon: "mdi-frequently-asked-questions",
    description: (party) => `Ver perguntas frequentes do evento ${party.name}`,
    keywords: ["faq", "perguntas frequentes", "dúvidas", "respostas"],
  },
  {
    type: "party.details",
    id: "table-map",
    name: "Mapa de Mesas",
    permission: 2,
    icon: "mdi-table-chair",
    keywords: ["mesa", "mapa de mesas", "mesas", "acentos", "lugar marcado"],
  },
  {
    type: "party.details",
    id: "map",
    name: "Mapa 3D",
    permission: 2,
    icon: "mdi-map",
  },
  {
    type: "party.details",
    id: "rating",
    name: "Avaliações",
    permission: 1048576,
    icon: "mdi-star",
    description: (party) => `Ver avaliações do evento ${party.name}`,
  },
  {
    type: "party.details",
    id: "wa-group",
    name: "Grupos do Whatsapp",
    permission: 2097152,
    icon: "mdi-whatsapp",
  },
  {
    type: "transactions.party",
    name: "Financeiro",
    permission: 2048,
    icon: "mdi-cash",
  },
];

export default async () => {
  const parties = await store.dispatch("organization/updateParties");
  const activeParties = parties.filter((p) => {
    const ps = new partyStatus(p);
    return ps.isActive;
  });
  const items = [];

  activeParties.forEach((p) => {
    const ps = new partyStatus(p);
    if (!ps.isActive) return;
    tabs.forEach((t) => {
      const description =
        typeof t.description === "function" ? t.description(p) : t.description;
      const item = {
        id: `party:${t.type}:${p.id}:${t.id}`,
        title: `${p.name} — ${t.name}`,
        description: description || `Gerenciar ${t.name} do evento ${p.name}`,
        keywords: [p.name, t.name],
        permission: t.permission,
        relevantIn: [
          `/admin/organization/parties/${p.id}`,
          `/admin/organization/parties/${p.id}/management`,
        ],
        icon: t.icon,
        background: p.cover,
        data: {
          to: `admin.${t.type}`,
          params: { partyId: p.id },
          hash: t.id ? `#${t.id}` : "",
        },
        type: "internal",
      };
      items.push(item);
    });
  });

  return items;
};
