<template>
  <v-dialog
    v-model="isOpen"
    width="700"
    persistent
    scrollable
    :fullscreen="$vuetify.breakpoint.smAndDown"
    content-class="rounded-lg"
  >
    <v-card class="h-full d-flex flex-column" style="max-height: 90dvh">
      <v-card-title class="pb-0 pt-3 px-6">
        <span class="text-16"> Adicionar kits em lote </span>
      </v-card-title>

      <v-card-text class="pa-0 ma-0">
        <vue-perfect-scrollbar
          :settings="{ suppressScrollX: true, wheelPropagation: false }"
          style="height: 100%"
        >
          <v-stepper v-model="step" class="elevation-0" :non-linear="!!kit?.id">
            <v-stepper-header class="elevation-0 mb-2" style="height: 50px">
              <template v-for="(stp, i) in steps">
                <v-divider v-if="i" :key="`divider-` + i" />
                <v-stepper-step
                  :key="`step-` + i"
                  :complete="step > i + 1"
                  :step="i + 1"
                  :editable="!!kit?.id && !(!validInfo || !validMembership)"
                  class="py-0"
                >
                  {{ stp }}
                </v-stepper-step>
              </template>
            </v-stepper-header>

            <v-stepper-items>
              <v-stepper-content step="1" class="pt-0">
                <div class="d-flex mt-2">
                  <v-text-field
                    v-model="startIn"
                    min="1"
                    label="Iniciar em"
                    type="number"
                    class="mr-2"
                    outlined
                    dense
                  ></v-text-field>
                  <v-text-field
                    v-model="endIn"
                    :min="startIn + 1"
                    label="Finalizar em"
                    type="number"
                    class="mr-2"
                    outlined
                    dense
                  ></v-text-field>
                </div>
                <v-alert color="info" text dense>
                  Use <code>{n}</code> no nome para preenchimento automático
                </v-alert>
                <kit-info
                  v-model="kit"
                  @valid="validInfo = $event"
                  :party="party"
                />
              </v-stepper-content>

              <v-stepper-content step="2" class="pt-0">
                <kit-items
                  v-model="selectedItems"
                  @valid="validItems = $event"
                  :itemsOptions="itemsOptions"
                />
                <v-text-field
                  v-if="
                    selectedItems.some(

                      ({ TicketBlock }) => TicketBlock?.id === 'create'
                    )
                  "
                  label="Nome do lote"
                  v-model="autoTicketBlockName"
                  outlined
                  dense
                  :rules="[
                    (v) => !!v || 'Campo obrigatório',
                    v.includes('{n}') ||
                      'Use {n} no nome para preenchimento automático',
                  ]"
                >
                </v-text-field>
              </v-stepper-content>

              <!-- <v-stepper-content :step="steps.length" class="pt-0">
                <kit-items v-model="items" :options="itemsOptions" />
                <v-alert
                  v-if="!!error"
                  outlined
                  text
                  color="error"
                  class="mt-2"
                >
                  {{ error }}
                </v-alert>
              </v-stepper-content> -->
              <v-stepper-content step="3" class="pt-0">
                <ticket-block-sellers
                  v-model="sellers"
                  :members="members"
                  :posSessions="posSessions"
                />
                <v-alert
                  v-if="!!error"
                  outlined
                  text
                  color="error"
                  class="mt-2"
                >
                  {{ error }}
                </v-alert>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </vue-perfect-scrollbar>
      </v-card-text>

      <v-card-actions class="d-flex align-end gap-1">
        <template v-if="step === 1">
          <v-switch
            class="mt-0"
            v-model="kit.active"
            label="Ativo"
            :messages="[kit.active ? 'Kit ativo' : 'Kit inativo']"
          />
          <v-spacer />
          <v-btn text :disabled="loading" @click="close"> Cancelar </v-btn>
          <v-btn
            color="primary"
            :disabled="!validInfo || loading"
            @click="step++"
          >
            Continuar
          </v-btn>
        </template>
        <template v-if="step === 2">
          {{
            !validItems
              ? "Nenhuma item selecionado"
              : `${countedItems} item${
                  countedItems != 1 ? "s" : ""
                } selecionado${countedItems != 1 ? "s" : ""}`
          }}
          <v-spacer />
          <v-btn class="ml-1" text :disabled="loading" @click="step--">
            Voltar
          </v-btn>
          <v-btn
            color="primary"
            :disabled="!validItems || loading"
            @click="step++"
            class="ml-2"
          >
            Continuar
          </v-btn>
        </template>
        <template v-if="step === steps.length">
          <!-- <p class="align-self-center mb-0">
            {{ sellers.length }} vendedor{{
              sellers.length != 1 ? "es" : ""
            }}
            selecionado{{ sellers.length != 1 ? "s" : "" }}
          </p> -->
          <v-spacer />
          <v-btn class="ml-1" text @click="step--" :disabled="loading">
            Voltar
          </v-btn>
          <v-btn
            color="primary"
            :disabled="loading"
            :loading="loading"
            @click="save"
          >
            {{ kit?.id ? "Salvar" : "Criar" }}
          </v-btn>
        </template>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import ORGANIZATION from "@/services/admin/organization";
import TICKET_SERVICE from "@/services/admin/ticket";
import moment from "moment";
import KitInfo from "./modules/KitInfo.vue";
import KitItems from "./modules/KitItems.vue";
import TicketBlockMembership from "./modules/TicketBlockMembership.vue";
import TicketBlockSellers from "./modules/TicketBlockSellers.vue";
import TICKET from "@/services/admin/ticket";

const defaultKit = () => ({
  id: null,
  name: "",
  description: "",
  price: 0,
  startDate: new Date().toISOString().substr(0, 16),
  endDate: new Date().toISOString().substr(0, 16),
  active: true,
  onlineSale: false,
  ticketGroupId: null,
});

export default {
  components: { KitInfo, KitItems, TicketBlockSellers },
  data: () => ({
    startIn: 1,
    endIn: 10,
    isOpen: false,
    loading: false,
    validInfo: false,
    validItems: false,
    validMembership: false,
    error: false,
    step: 1,
    autoTicketBlockName: "",
    kit: defaultKit(),
    members: [],
    posSessions: [],
    sellers: [],
    items: [],
    itemsOptions: [],
    selectedItems: [
      {
        TicketBlock: null,
        quantity: 1,
      },
    ],
  }),

  methods: {
    open(data = {}) {
      this.isOpen = true;
      data = this.formatDate(data);
      this.sellers = this.processSellers(data);
      this.kit = Object.assign(defaultKit(), data);
      this.getData();
    },
    close() {
      this.isOpen = false;
      this.loading = false;
      this.error = false;
      this.step = 1;
      this.sellers = [0];
      this.kit = defaultKit();
    },
    async getData() {
      await this.getItemsOptions();
      await this.getMembers();
    },
    async save() {
      try {
        this.loading = true;
        this.error = false;
        const orgId = this.selectedOrganization.id;
        const { partyId } = this.$route.params;
        const sellersGrouped = this.sellers.reduce(
          (acc, s) => {
            if (!acc[s.type]) acc[s.type] = [];
            acc[s.type].push(s.id);
            return acc;
          },
          { user: [], pos: [] }
        );
        const kitData = {
          ticketGroupId: this.kit.ticketGroupId,
          price: this.kit.price,
          quantity: parseInt(this.kit.quantity),
          sellers: sellersGrouped.user,
          posSellers: sellersGrouped.pos,
          startDate: moment(this.kit.startDate).toISOString(),
          endDate: moment(this.kit.endDate).toISOString(),
          active: this.kit.active,
          onlineSale: this.kit.onlineSale,
        };

        let start = parseInt(this.startIn);
        let end = parseInt(this.endIn);

        for (let n = start; n <= end; n++) {
          const name = this.kit.name.replace("{n}", n);

          const itens = [];

          for (const item of this.selectedItems) {
            if (item.TicketBlock.id !== "create") {
              itens.push({
                ticketBlockId: item.TicketBlock.id,
                quantity: item.quantity,
              });
              continue;
            }

            var ticketBlockName = name;
            if (this.autoTicketBlockName)
              ticketBlockName = this.autoTicketBlockName.replace("{n}", n);

            const { ticketBlock } = await TICKET.createTicketBlock(
              orgId,
              partyId,
              {
                ticketGroup: this.kit.ticketGroupId,
                name: ticketBlockName,
                price: this.kit.price / this.selectedItems.length,
                quantity: parseInt(this.kit.quantity * item.quantity),
                startDate: this.kit.startDate,
                endDate: this.kit.endDate,
                allowTransfer: true,
                active: true,
                onlineSale: false,
                sellers: sellersGrouped.user,
                posSellers: sellersGrouped.pos,
              }
            );

            itens.push({
              TicketBlock: {
                id: ticketBlock.id,
              },
              quantity: item.quantity,
            });
          }

          await TICKET_SERVICE.createKit(orgId, partyId, {
            ...kitData,
            name,
            items: {
              ticketBlockList: itens,
            },
          });
        }

        this.$emit("success");
        this.close();
      } catch (e) {
        this.error = e.message;
        this.loading = false;
      }
    },

    async getItemsOptions() {
      try {
        const orgId = this.selectedOrganization.id;
        const { partyId } = this.$route.params;
        const response = await TICKET_SERVICE.getKitItemsOptions(
          orgId,
          partyId,
          this.kit.ticketGroupId
        );
        this.itemsOptions = response.data;
        this.itemsOptions.push({
          id: "create",
          name: "Criar novo item automático",
        });
      } catch (error) {
        this.error = error.message;
        this.loading = false;
      }
    },

    processSellers(data) {
      const sellers = [];
      if (data.Sellers)
        sellers.push(
          ...this.members
            .filter((member) => data.Sellers.some((s) => s.id == member.id))
            .map((s) => ({ ...s, type: "user" }))
        );
      if (data.PosSellers)
        sellers.push(
          ...this.posSessions
            .filter((session) =>
              data.PosSellers.some((s) => s.id == session.id)
            )
            .map((s) => ({ ...s, type: "pos" }))
        );
      return sellers;
    },
    formatDate(data) {
      const formattedData = {
        ...data,
        startDate: data.startDate
          ? moment(data.startDate).format("YYYY-MM-DDTHH:mm")
          : data.startDate,
        endDate: data.endDate
          ? moment(data.endDate).format("YYYY-MM-DDTHH:mm")
          : data.endDate,
      };
      return formattedData;
    },

    async getMembers() {
      try {
        this.loading = true;
        var response = await ORGANIZATION.members(this.selectedOrganization.id);
        this.members = response.users.filter((u) => {
          return (
            u.owner ||
            (32 & u.permission) === 32 ||
            (1024 & u.permission) === 1024
          );
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
    formatName(name) {
      return name
        .split(" ")
        .map((n) => (n.length > 3 ? n.charAt(0).toUpperCase() + n.slice(1) : n))
        .join(" ");
    },
  },
  watch: {
    "kit.name": {
      handler: function (val) {
        this.kit.name = this.formatName(val);
      },
      immediate: true,
    },
  },
  computed: {
    ...mapGetters("organization", ["memberships", "selectedOrganization"]),
    steps() {
      const data = ["Informações", "Items", "Vendedores"];
      return data;
    },
    countedItems() {
      return this.selectedItems.reduce((acc, item) => acc + item.quantity, 0);
    },
  },
  mounted() {
    this.$root.$on("kit-multiple-modal", this.open);
  },
  props: {
    party: {
      type: Object,
      default: () => ({}),
    },
  },
  created() {},
};
</script>

<style></style>
