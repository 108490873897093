<template>
  <div class="d-flex flex-column gap-3">
    <v-data-table
      :headers="headers"
      :items="data?.cashouts || []"
      :loading="loading"
      :items-per-page="5"
    >
      <template v-slot:item.id="{ item }"> #{{ item.id | shortId }} </template>
      <template v-slot:item.createdAt="{ item }">
        {{ item.createdAt | date("DD/MM/YYYY HH:mm") }}
      </template>
      <template v-slot:item.amount="{ item }">
        {{ item.amount | currency }}
      </template>
      <template v-slot:item.User.name="{ item }">
        <div class="d-flex align-center gap-1">

          <base-avatar :src="item.User.photo" :seed="item.User.id" :size="18" />
          {{ item.User.name }}
        </div>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import PAYMENT from "@/utils/payment";

export default {
  props: {
    session: {
      type: Object,
      required: true,
    },
    data: {
      type: Object,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    PAYMENT,
    headers: [
      { text: "#", value: "id" },
      { text: "Data", value: "createdAt" },
      { text: "Valor", value: "amount" },
      { text: "Responsavel", value: "User.name" },
    ],
  }),
};
</script>

<style>
</style>