<template>
  <div>
    <!-- <template>
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">Name</th>
              <th class="text-left">Calories</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in desserts" :key="item.name">
              <td>{{ item.name }}</td>
              <td>{{ item.calories }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </template> -->
  </div>
</template>

<script>
export default {
  props: {
    reportData: {
      type: Array,
      required: true,
    },
  },
  data: () => ({}),
  methods: {},
  computed: {},
};
</script>

<style></style>
