import http from "@/http-common";

const getAll = async (orgId, { includeDisconnected = false, partyId } = {}) => {
  return await http
    .get(`/admin/organization/${orgId}/pos`, {
      params: { includeDisconnected, partyId },
    })
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};

const editPos = async (orgId, id, data) => {
  return await http
    .put(`/admin/organization/${orgId}/pos/${id}`, data)
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};

const adoptVerifyCode = async (orgId, code) => {
  return await http
    .get(`/admin/organization/${orgId}/pos/adopt?code=${code}`)
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};
const adoptSearchSN = async (orgId, sn) => {
  return await http
    .get(`/admin/organization/${orgId}/pos/adopt/search?sn=${sn}`)
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};

const cashoutVerify = async (orgId, id) => {
  return await http
    .get(`/admin/organization/${orgId}/pos/${id}/cashout`)
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};

const adoptAdopt = async (orgId, data) => {
  return await http
    .post(`/admin/organization/${orgId}/pos/adopt`, data)
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};

const cashout = async (orgId, sessionId, amount) => {
  return await http
    .post(`/admin/organization/${orgId}/pos/${sessionId}/cashout`, {
      amount,
    })
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};

const report = async (orgId, sessionId) => {
  return await http
    .get(`/admin/organization/${orgId}/pos/${sessionId}/report`)
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};

export default {
  getAll,
  editPos,
  cashoutVerify,
  cashout,
  report,
  adopt: {
    adopt: adoptAdopt,
    searchSN: adoptSearchSN,
    verifyCode: adoptVerifyCode,
  },
};
