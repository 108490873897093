<template>
  <v-expansion-panels
    v-model="expanded"
    focusable
    :popout="$vuetify.breakpoint.mdAndUp"
    class="mt-4 outlined elevation-0"
  >
    <v-expansion-panel
      v-for="(tGroup, i) in tGroups"
      :key="i"
      class="elevation-0"
    >
      <v-expansion-panel-header :class="expanded === i ? 'pt-8' : ''">
        <v-progress-linear
          class="rounded-b-0"
          :height="22"
          style="position: absolute; top: 0; left: 0; animation: normal"
          :style="{ 'max-height': expanded === i ? '100%' : '5px' }"
          :value="tGroup.quantity.percentage"
          :rounded="expanded === i || i === 0"
        >
          <v-scroll-y-transition leave-absolute>
            <b class="text-14 ma-0" v-if="expanded === i">
              {{ tGroup.quantity.total - tGroup.quantity.remaining }} de
              {{ tGroup.quantity.total }} ingresso{{
                tGroup.quantity.total != 1 ? "s" : ""
              }}
              vendido{{ tGroup.quantity.total != 1 ? "s" : "" }}
            </b>
          </v-scroll-y-transition>
        </v-progress-linear>
        <span class="font-weight-bold">
          <span class="mr-2 lh-3">{{ tGroup.name }}</span>
          <v-scroll-y-transition>
            <v-chip
              v-if="tGroup.maxTickets"
              class="mr-2 font-weight-medium"
              x-small
            >
              {{ tGroup.maxTickets }} por pessoa
            </v-chip>
          </v-scroll-y-transition>
          <v-scroll-y-transition>
            <v-chip
              v-if="tGroup.requireBiometry"
              class="mr-2 font-weight-medium"
              x-small
            >
              <v-icon x-small left>mdi-face-recognition</v-icon>
              Biometria facial
            </v-chip>
          </v-scroll-y-transition>

          <v-scroll-y-transition>
            <v-chip
              v-if="tGroup.tableGroup && expanded !== i"
              class="mr-2 font-weight-medium"
              x-small
            >
              <v-icon x-small left> mdi-table-chair </v-icon>
              {{ tGroup.tableGroup.name }}
            </v-chip>
          </v-scroll-y-transition>
          <v-scroll-y-transition>
            <v-chip
              v-if="tGroup.WhatsappGroups.length && expanded !== i"
              class="mr-2 font-weight-medium"
              x-small
            >
              <v-icon x-small left>mdi-shield-alert</v-icon>
              com restrição
            </v-chip>
          </v-scroll-y-transition>
        </span>
        <v-spacer />
        <div
          class="d-flex align-center justify-space-between mx-2 flex-grow-0 gap-2"
        >
          <span class="text--disabled caption ma-0">
            {{ tGroup.TicketBlock.length }} lote{{
              tGroup.TicketBlock.length != 1 ? "s" : ""
            }}
          </span>

          <v-btn
            v-if="expanded === i"
            :icon="$vuetify.breakpoint.mdAndDown"
            small
            :color="$vuetify.breakpoint.mdAndDown ? '' : 'primary'"
            @click.stop="addGroup(tGroup)"
          >
            <v-icon small :left="!$vuetify.breakpoint.mdAndDown">
              mdi-pencil
            </v-icon>
            {{ $vuetify.breakpoint.mdAndDown ? "" : "Setor" }}
          </v-btn>
        </div>
      </v-expansion-panel-header>
      <v-expansion-panel-content class="pt-2">
        <v-alert
          type="warning"
          text
          dense
          v-if="tGroup.WhatsappGroups.length"
          icon="mdi-shield-alert"
        >
          <p class="mb-0 text-14">
            A venda online deste setor está restrita a membros dos seguintes
            grupos do WhatsApp:
          </p>
          <v-chip
            v-for="group in tGroup.WhatsappGroups"
            :key="group.id"
            class="ma-1 pl-0"
          >
            <div class="d-flex gap-2 align-center">
              <v-avatar style="width: 32px !important; height: 32px !important">
                <v-img
                  v-if="group.image"
                  width="32px"
                  height="32px"
                  :src="group.image"
                />
                <v-icon v-else small class="pl-2">mdi-account-group</v-icon>
              </v-avatar>
              {{ group.name }}
            </div>
          </v-chip>
        </v-alert>
        <v-alert
          v-if="party.active && tGroup.tableGroup"
          color="info"
          text
          dense
        >
          <div class="d-flex flex-wrap gap-3 align-center">
            <div class="flex-grow-1 d-flex align-center gap-3">
              <v-icon color="info">mdi-table-chair</v-icon>
              <div>
                <p class="mb-0 text-14">
                  Os ingressos deste setor serão alocados no grupo
                  {{ tGroup.tableGroup.name }}
                </p>
                <small>
                  Caso não haja mesas disponíveis, o setor não poderá ser
                  vendido.
                </small>
              </div>
            </div>
            <div outlined class="text-center flex-grow-1 flex-shrink-0">
              <p class="mb-0 lh-1 font-weight-bold">
                {{ tGroup.tableGroup.capacity }}
              </p>
              <small class="lh-1">Capacidade</small>
            </div>
            <div outlined class="text-center flex-grow-1 flex-shrink-0">
              <p class="mb-0 lh-1 font-weight-bold">
                {{ tGroup.tableGroup.availableTables.length }} /
                {{ tGroup.tableGroup.Tables.length }}
              </p>
              <small class="lh-1">Disponiveis</small>
            </div>
          </div>
        </v-alert>
        <div class="flex-grow-1 flex-shrink-0">
          <span
            v-if="config.opMode"
            class="text-overline lh-1 font-weight-medium"
          >
            #{{ tGroup.id | shortId }}
          </span>
          <h6 class="">Descrição</h6>
          {{ tGroup.description || "Sem descrição" }}
        </div>
        <v-divider class="my-4" />
        <div class="d-flex align-center justify-space-between">
          <h6 class="mb-0">Lotes</h6>
          <div v-if="party.active" class="mb-2">
            <v-btn
              v-if="config.opMode"
              class="ml-1"
              color="primary"
              icon
              @click="pasteTicketBlock(tGroup)"
              :small="$vuetify.breakpoint.mdAndDown"
            >
              <v-icon small>mdi-content-paste</v-icon>
            </v-btn>

            <v-btn
              v-if="!tGroup.KitList.length"
              class="ml-1"
              color="primary"
              text
              @click="addKit({ ticketGroupId: tGroup.id })"
              :small="$vuetify.breakpoint.mdAndDown"
            >
              <v-icon small left>mdi-plus</v-icon>
              Kit
            </v-btn>
            <v-btn
              class="ml-1"
              color="primary"
              @click="addBlock({ ticketGroup: tGroup.id })"
              :small="$vuetify.breakpoint.mdAndDown"
            >
              <v-icon small left>mdi-plus</v-icon>
              Lote
            </v-btn>
          </div>
          <v-alert v-else dense type="warning" text border="left" class="mb-0">
            Não é possível editar lotes de um evento inativo ou encerrado.
          </v-alert>
        </div>

        <v-data-iterator
          :items="tGroup.TicketBlock"
          :key-field="(tBlock) => tBlock.id"
          :footer-props="{ itemsPerPageOptions: [12, 24, 48] }"
          :items-per-page="12"
          :hide-default-footer="tGroup.TicketBlock.length <= 12"
          :search="blockSearch"
          :custom-filter="filterFunc"
        >
          <template v-slot:header>
            <v-text-field
              v-if="tGroup.TicketBlock.length > 12"
              v-model="blockSearch"
              append-icon="mdi-magnify"
              label="Pesquisar"
              single-line
              hide-details
              outlined
              dense
            ></v-text-field>
          </template>

          <template v-slot:no-results>
            <v-alert
              :value="true"
              color="info"
              icon="mdi-information"
              text
              dense
              class="mb-0 mt-2"
            >
              Nenhum lote encontrado para a pesquisa.
            </v-alert>
          </template>

          <template v-slot="{ items }">
            <v-row>
              <v-col
                v-for="ticketBlock in items"
                :key="ticketBlock.id"
                cols="12"
                sm="6"
                lg="4"
                xl="3"
              >
                <ticket-block-management
                  :ticketBlock="ticketBlock"
                  :party="party"
                />
              </v-col>
            </v-row>
          </template>
        </v-data-iterator>
        <div v-if="tGroup.KitList.length" class="mt-4">
          <v-divider class="my-4" />
          <div class="d-flex align-center justify-space-between mb-2">
            <h6 class="mb-0">Kits</h6>
            <div v-if="party.active">
              <v-btn
                v-if="config.opMode"
                class="ml-1"
                color="primary"
                icon
                @click="pasteKit(tGroup)"
                :small="$vuetify.breakpoint.mdAndDown"
              >
                <v-icon small>mdi-content-paste</v-icon>
              </v-btn>
              <v-btn
                v-if="config.opMode"
                class="ml-1"
                color="primary"
                icon
                @click="createMultipleKit({ ticketGroupId: tGroup.id })"
                :small="$vuetify.breakpoint.mdAndDown"
              >
                <v-icon small>mdi-plus-box-multiple</v-icon>
              </v-btn>
              <v-btn
                class="ml-1"
                color="primary"
                @click="addKit({ ticketGroupId: tGroup.id })"
                :small="$vuetify.breakpoint.mdAndDown"
              >
                <v-icon small left>mdi-plus</v-icon>
                Kit
              </v-btn>
            </div>
            <v-alert
              v-else
              dense
              type="warning"
              text
              border="left"
              class="mb-0"
            >
              Não é possível editar kits de um evento inativo ou encerrado.
            </v-alert>
          </div>
          <v-data-iterator
            :items="tGroup.KitList"
            :key-field="({ id }) => id"
            :footer-props="{ itemsPerPageOptions: [12, 24, 48] }"
            :items-per-page="12"
            :hide-default-footer="tGroup.KitList.length <= 12"
            :search="kitSearch"
            outlined
            dense
            :custom-filter="filterFunc"
          >
            <template v-slot:header>
              <v-text-field
                v-if="tGroup.KitList.length > 12"
                v-model="kitSearch"
                append-icon="mdi-magnify"
                label="Pesquisar"
                single-line
                hide-details
                outlined
                dense
              ></v-text-field>
            </template>
            <template v-slot:no-results>
              <v-alert
                :value="true"
                color="info"
                icon="mdi-information"
                text
                dense
                class="mb-0 mt-2"
              >
                Nenhum kit encontrado para a pesquisa.
              </v-alert>
            </template>
            <template v-slot="{ items }">
              <v-row>
                <v-col
                  v-for="kit in items"
                  :key="kit.id"
                  cols="12"
                  sm="6"
                  lg="3"
                  xl="2"
                >
                  <kit-management :kit="kit" :party="party" />
                </v-col>
              </v-row>
            </template>
          </v-data-iterator>
        </div>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import { mapGetters } from "vuex";
import TicketBlockManagement from "./TicketBlockManagement";
import KitManagement from "./KitManagement";

export default {
  components: { TicketBlockManagement, KitManagement },
  data: () => ({
    expanded: null,
    blockSearch: "",
    kitSearch: "",
  }),
  methods: {
    addGroup(tGroup) {
      this.$root.$emit("ticket-group-modal", tGroup);
    },
    addBlock(obj) {
      this.$root.$emit("ticket-block-modal", obj);
    },
    addKit(obj) {
      this.$root.$emit("kit-modal", obj);
    },
    createMultipleKit(obj) {
      this.$root.$emit("kit-multiple-modal", obj);
    },

    filterFunc(items, search) {
      return items.filter((item) => {
        return item.name.toLowerCase().includes(search.toLowerCase());
      });
    },
    async getClipboard() {
      try {
        var clipboard = window.sessionStorage.getItem("copy-op");
        if (!clipboard) clipboard = await navigator.clipboard.readText();
        return JSON.parse(clipboard);
      } catch (e) {
        throw new Error("Não foi possível acessar a área de transferência");
      }
    },

    async pasteTicketBlock(tGroup) {
      try {
        var clipboard = await this.getClipboard();

        if (clipboard._copyType !== "ticket-block")
          throw new Error("Não é um lote");

        this.$root.$emit("ticket-block-modal", {
          ...clipboard,
          ticketGroup: tGroup.id,
        });
      } catch (e) {
        alert(e.message);
      }
    },
    async pasteKit(tGroup) {
      try {
        var clipboard = await this.getClipboard();

        if (clipboard._copyType !== "kit") throw new Error("Não é um kit");

        this.$root.$emit("kit-modal", {
          ...clipboard,
          ticketGroupId: tGroup.id,
        });
      } catch (e) {
        alert(e.message);
      }
    },
  },
  computed: {
    ...mapGetters("auth", ["config"]),

    tGroups() {
      return this.ticketGroups.map((tGroup) => {
        const remaining = tGroup.TicketBlock.reduce(
          (abb, tBlock) => {
            abb.total += tBlock.quantity;
            abb.remaining += tBlock.tickets.remaining;
            return abb;
          },
          { total: 0, remaining: 0 }
        );

        remaining.percentage =
          ((remaining.total - remaining.remaining) / remaining.total) * 100;

        const tableGroup =
          tGroup.tableGroupId && this.tableMap?.Groups
            ? this.tableMap.Groups.find((g) => g.id === tGroup.tableGroupId)
            : null;

        return {
          ...tGroup,
          TicketBlock: tGroup.TicketBlock.sort((a, b) => a.price - b.price),
          quantity: remaining,
          tableGroup: tableGroup
            ? {
                ...tableGroup,
                availableTables: tableGroup.Tables.filter((t) => !t.Ticket),
              }
            : null,
        };
      });
    },
  },
  mounted() {
    if (this.ticketGroups.length == 1) this.expanded = 0;
  },
  watch: {
    expanded(val) {
      this.kitSearch = "";
      this.blockSearch = "";
    },
  },
  props: {
    ticketGroups: {
      type: Array,
      default: () => [],
    },
    party: {
      type: Object,
      default: () => ({}),
    },
    tableMap: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>

<style></style>
