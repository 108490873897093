<template>
  <v-card outlined elevation="3" rounded="lg">
    <v-progress-linear
      :color="`blue-grey ${$vuetify.theme.dark ? 'darken-4' : 'lighten-4'}`"
      :background-color="`${progressColor} lighten-1`"
      :value="
        ((ticketBlock.quantity - ticketBlock.tickets.remaining) /
          ticketBlock.quantity) *
        100
      "
      height="20"
    >
      <div class="d-flex caption ml-2 justify-space-around w-full">
        <b>{{ ticketBlock.quantity }} Total</b>
        <b v-if="ticketBlock.tickets.remaining > 0">
          {{ ticketBlock.tickets.remaining }} Disponíve{{
            ticketBlock.tickets.remaining != 1 ? "is" : "l"
          }}
        </b>
        <b v-else> Esgotado</b>
      </div>
    </v-progress-linear>
    <div class="px-4 pb-3 pt-2">
      <div class="mb-4">
        <div v-if="party.active" class="float-right">
          <v-btn 
          v-if="config.opMode"
          icon small @click="copy">
            <v-icon x-small>mdi-content-copy</v-icon>
          </v-btn>
          <v-btn text small @click="addBlock(ticketBlock)">
            <v-icon x-small left>mdi-pencil</v-icon>
            Lote
          </v-btn>
        </div>
        <div>
          <span v-if="config.opMode" class="text-overline lh-1">
            #{{ ticketBlock.id | shortId }}
          </span>
          <h6 class="mb-0">
            {{ ticketBlock.name }}
          </h6>
          <v-tooltip top color="black" :disabled="!membershipPrices.length">
            <template v-slot:activator="{ on }">
              <h5 v-on="on" class="mb-0 font-weight-medium">
                {{ ticketBlock.price | currency(true) }}
                <template v-if="membershipPrices.length">
                  | <v-icon small>mdi-card-account-details-star</v-icon>
                </template>
              </h5>
            </template>
            <div class="d-flex flex-column gap-1">
              <div
                v-for="msPrice in membershipPrices"
                :key="msPrice.membershipId"
                class="d-flex gap-2 align-center my-2"
              >
                <v-chip
                  :color="msPrice.membership.backgroundColor"
                  text-color="white"
                  label
                  small
                  class="text-13 font-weight-bold"
                  :style="{
                    background: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(${msPrice.membership.backgroundImg})`,
                    backgroundSize: 'cover',
                    border: `1px solid ${
                      msPrice.membership.backgroundColor || '#000'
                    }`,
                    boxShadow: `0 0 10px  ${
                      msPrice.membership.backgroundColor || 'rgba(0,0,0,.2)'
                    }`,
                  }"
                >
                  {{ msPrice.membership.name }}
                </v-chip>
                <b>{{ msPrice.price | currency(true) }}</b>
              </div>
            </div>
          </v-tooltip>
        </div>
      </div>
      <div class="absolute bottom d-flex align-end justify-space-between">
        <div>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <p class="mb-0 lh-1" v-bind="attrs" v-on="on">
                {{ sellers.total }} vendedor{{ sellers.total != 1 ? "es" : "" }}
              </p>
            </template>
            <p class="mb-0">
              {{ sellers.users }} Promoter{{ sellers.users != 1 ? "s" : "" }}
            </p>
            <p class="mb-0">
              {{ sellers.pos }} PDV{{ sellers.pos != 1 ? "s" : "" }}
            </p>
          </v-tooltip>

          <v-chip x-small label :color="status.color" class="font-weight-bold">
            {{ status.text }}
          </v-chip>
        </div>

        <div>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-icon right v-on="on">
                mdi-{{
                  ticketBlock.allowTransfer ? "account-switch" : "account-lock"
                }}
              </v-icon>
            </template>
            <span>
              Transferência
              <b>
                {{ ticketBlock.allowTransfer ? "permitida" : "não permitida" }}
              </b>
            </span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-icon right v-on="on">
                mdi-earth{{ ticketBlock.onlineSale ? "" : "-off" }}
              </v-icon>
            </template>
            <span>
              Venda online
              <b>{{
                ticketBlock.onlineSale ? "permitida" : "não permitida"
              }}</b>
            </span>
          </v-tooltip>
        </div>
      </div>
    </div>
  </v-card>
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";
export default {
  methods: {
    addBlock(obj) {
      this.$root.$emit("ticket-block-modal", obj);
    },
    copy() {
      var name = this.ticketBlock.name;

      if (/\d/.test(name))
        name = name.replace(/\d+/g, function (n) {
          return ++n;
        });
      else name = `${name} (Cópia)`;

      const json = JSON.stringify({
        ...this.ticketBlock,
        _copyType: "ticket-block",
        id: undefined,
        name: name,
      });

      window.sessionStorage.setItem("copy-op", json);

      navigator.clipboard.writeText(json);
      alert("Lote copiado para a área de transferência");
    },
  },
  computed: {
    ...mapGetters("organization", ["memberships"]),
    ...mapGetters("auth", ["config"]),

    membershipPrices() {
      const { memberships } = this;
      if (!memberships) return [];
      const { TicketBlockMembership } = this.ticketBlock;
      if (!TicketBlockMembership) return [];
      return TicketBlockMembership.map((t) => {
        const membership = memberships.find((m) => m.id == t.membershipId);
        return {
          ...t,
          membership,
        };
      });
    },
    sellers() {
      return {
        total:
          this.ticketBlock.Sellers.length + this.ticketBlock.PosSellers.length,
        users: this.ticketBlock.Sellers.length,
        pos: this.ticketBlock.PosSellers.length,
      };
    },
    progressColor() {
      const ticketBlock = this.ticketBlock;
      const percentage =
        (ticketBlock.quantity - ticketBlock.tickets.remaining) /
        ticketBlock.quantity;

      return percentage > 0.9
        ? "error"
        : percentage > 0.7
        ? "warning"
        : "primary";
    },
    status() {
      const ticketBlock = this.ticketBlock;
      if (!ticketBlock.active)
        return {
          text: "Inativo",
          color: "error",
        };
      if (moment().isAfter(ticketBlock.endDate))
        return {
          text: "Encerrado",
          color: "error",
        };
      if (!ticketBlock.startDate || moment().isBefore(ticketBlock.startDate))
        return {
          text: "Aguardando",
          color: "warning",
        };
      if (ticketBlock.tickets.remaining == 0)
        return {
          text: "Esgotado",
          color: "error",
        };
      return {
        text: "Ativo",
        color: "success",
      };
    },
  },
  props: {
    ticketBlock: {
      type: Object,
      required: true,
    },
    party: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style></style>
