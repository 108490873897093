<template>
  <v-card outlined :loading="loading">
    <v-card-title class="justify-space-between">
      <h6 class="mb-0">Vendas por Setor</h6>
    </v-card-title>
    <v-card-text>
      <v-data-table
        :items="ticketGroups"
        :headers="headers"
        hide-default-footer
        single-expand
        item-key="id"
        :items-per-page="ticketGroups.length"
        show-expand
        :expanded.sync="expanded"
      >
        <template v-slot:item.count="{ item }">
          {{ item.count }} ingresso{{ item.count == 1 ? "" : "s" }}
        </template>
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length" class="pa-2">
            <v-simple-table dense>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">Lote</th>
                    <th class="text-right">Qtde</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="ticket in item.TicketBlock" :key="ticket.id">
                    <td>
                      <b class="d-block">{{ ticket.name }}</b>
                    </td>
                    <td class="text-right">{{ ticket.count }} Ingressos</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </td>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import ApexCharts from "vue-apexcharts";

export default {
  components: {
    ApexCharts,
  },
  data: () => ({
    error: null,
    display: 0,
    expanded: [],
    headers: [
      {
        text: "Setor",
        value: "name",
        align: "left",
      },
      { text: "Qtde", value: "count", align: "right" },
    ],
  }),
  methods: {
    formatNumber(value) {
      return this.display === 0
        ? value
          ? this.$options.filters.currency(value, true)
          : ""
        : value.toFixed(0);
    },
  },
  computed: {
    ticketGroups() {
      return Object.values(
        this.data.tickets.reduce((acc, t) => {
          if (t.Payment.status !== "succeeded") return acc;

          const block = t.TicketBlock;
          const group = block.TicketGroup;
          if (!acc[group.id])
            acc[group.id] = {
              name: group.name,
              id: group.id,
              TicketBlock: {},
              count: 0,
            };
          acc[group.id].count++;
          if (!acc[group.id].TicketBlock[block.id])
            acc[group.id].TicketBlock[block.id] = {
              name: block.name,
              count: 0,
            };
          acc[group.id].TicketBlock[block.id].count++;
          return acc;
        }, {})
      );
    },
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
  },
};
</script>

<style></style>
