<template>
  <v-dialog v-model="dialog" max-width="900">
    <v-card>
      <v-stepper v-model="step">
        <v-stepper-header>
          <v-stepper-step :complete="step > 1" step="1">
            Relatório
          </v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step :complete="step > 2" step="2">
            Filtros
          </v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step step="3"> Exportar </v-stepper-step>
        </v-stepper-header>

        <v-stepper-items>
          <v-stepper-content step="1">
            <v-card-text>
              <v-row dense align="stretch">
                <v-col
                  cols="6"
                  sm="4"
                  md="3"
                  v-for="report in reportTypes"
                  :key="report.id"
                >
                  <v-card
                    class="text-center pa-3 h-full d-flex flex-column justify-center"
                    outlined
                    :class="{
                      ' v-alert--text primary--text':
                        selectedReportId === report.id,
                    }"
                    @click="selectedReportId = report.id"
                  >
                    <v-icon
                      :color="selectedReportId === report.id ? 'primary' : ''"
                      large
                    >
                      {{ report.icon }}
                    </v-icon>
                    <h6 class="mb-0">{{ report.title }}</h6>
                    <p class="mb-0">{{ report.description }}</p>
                  </v-card>
                </v-col>
              </v-row>
            </v-card-text>
            <div class="d-flex justify-space-between">
              <v-btn text @click="close()">Cancelar</v-btn>
              <v-btn
                color="primary"
                @click="step = 2"
                :disabled="!selectedReportId"
              >
                Continuar
              </v-btn>
            </div>
          </v-stepper-content>
          <v-stepper-content step="2">
            <div v-if="loading" class="d-flex justify-center">
              <v-progress-circular indeterminate></v-progress-circular>
            </div>
            <v-alert v-else-if="error" type="error" text>
              Erro ao carregar filtros
            </v-alert>
            <v-card-text v-else>
              <component
                :is="components.filter"
                v-model="filterOption"
                @isValid="validFilter = $event"
                :ticketGroups="ticketGroups"
                :posSellers="posSellers"
              />
            </v-card-text>
            <v-card-actions
              v-if="!loading"
              class="d-flex justify-space-between"
            >
              <v-btn text @click="step = 1">Voltar</v-btn>
              <v-btn
                v-if="!error"
                color="primary"
                @click="step = 3"
                :disabled="!validFilter"
              >
                Continuar
              </v-btn>
            </v-card-actions>
          </v-stepper-content>
          <v-stepper-content step="3">
            <vue-html2pdf
              :show-layout="false"
              :float-layout="true"
              :enable-download="true"
              :preview-modal="false"
              :paginate-elements-by-height="1400"
              filename="Relatório"
              :pdf-quality="2"
              :manual-pagination="false"
              pdf-format="a4"
              pdf-orientation="portrait"
              pdf-content-width=""
              @progress="onProgress($event)"
              @hasDownloaded="hasDownloaded($event)"
              ref="html2Pdf"
            >
              <section class="v-application" slot="pdf-content">
                <v-card style="min-width: 800px">
                  <report-header
                    :party="party"
                    :selectedReport="selectedReport"
                  />
                  <applied-filters :filterOption="filterOption" />
                  <component
                    :is="components.view"
                    :filter="filterOption"
                    :reportData="filteredData"
                    :ticketGroups="ticketGroups"
                    :posSellers="posSellers"
                  />
                </v-card>
              </section>
            </vue-html2pdf>
            <v-card-actions
              v-if="!loading"
              class="d-flex justify-space-between"
            >
              <v-btn text @click="step = 2">Voltar</v-btn>
            </v-card-actions>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </v-card>
  </v-dialog>
</template>

<script>
import VueHtml2pdf from "vue-html2pdf";
import AppliedFilters from "../export/AppliedFilters.vue";
import ReportHeader from "../export/ReportHeader.vue";

const reportsComponents = {};
const context = require.context("./reports", true, /\.vue$/);

context.keys().forEach((key) => {
  const componentName = key.replace(/^\.\/(.*)\.\w+$/, "$1");
  reportsComponents[componentName] = () => context(key);
});

export default {
  props: {
    ticketGroups: {
      type: Array,
      required: true,
    },
    posSellers: {
      type: Array,
      required: true,
    },
    party: {
      type: Object,
      required: true,
    },
    reportData: {
      type: Object,
      required: true,
    },
  },
  components: { VueHtml2pdf, ReportHeader, AppliedFilters },
  data: () => ({
    dialog: false,
    step: 1,
    selectedReportId: null,
    loading: false,
    error: null,
    components: {},
    filterOption: {},
    validFilter: true,
    filteredData: {},

    reportTypes: [
      {
        id: "sales_by_pdv",
        title: "Vendas por PDV",
        description: "Relatório de vendas por PDV",
        icon: "mdi-cash-register",
        components: "salesByPdv",
      },
      {
        id: "sales_by_product",
        title: "Vendas por Promoter",
        description: "Relatório de vendas por Promoter",
        icon: "mdi-cash",
        components: "salesByProduct",
      },
    ],
  }),
  methods: {
    open() {
      this.dialog = true;
      this.step = 1;
    },
    close() {
      this.dialog = false;
    },
    async loadReport() {
      try {
        this.loading = true;
        this.error = null;
        this.components = {};
        const filterPath = `${this.selectedReport.components}/Filters`;

        if (reportsComponents[filterPath]) {
          const filterComponent = await reportsComponents[filterPath]();
          this.components.filter = filterComponent.default;
        } else
          throw new Error({ code: 404, message: "Relatório não encontrado" });

        const viewPath = `${this.selectedReport.components}/ReportView`;
        if (reportsComponents[viewPath]) {
          const viewComponent = await reportsComponents[viewPath]();
          this.components.view = viewComponent.default;
        } else
          throw new Error({ code: 404, message: "Relatório não encontrado" });
      } catch (e) {
        this.error = e.message || "Erro ao carregar relatório";
        console.error(e);
      } finally {
        this.loading = false;
      }
    },
    filteredReports() {
      return this.reportData.payments.filter((payment) => {
        if (
          this.filterOption.status &&
          !this.filterOption.status.includes(payment.status)
        )
          return false;
        if (this.filterOption.dates && !this.filterDates(payment)) return false;
        if (this.filterOption.ticketBlocks && !this.filterTicketBlocks(payment))
          return false;
        if (this.filterOption.sellers && !this.filterSellers(payment))
          return false;
        if (!this.filterOption.courtesy && !this.filterCourtesy(payment))
          return false;
        return true;
      });
    },
    filterDates(payment) {
      const [startDate, endDate] = this.filterOption.dates;
      const date = new Date(payment.createdAt);
      return date >= startDate && date <= endDate;
    },
    filterTicketBlocks(payment) {
      return !payment.Ticket.some((ticket) =>
        this.filterOption.ticketBlocks.includes(ticket.TicketBlock.id)
      );
    },
    filterSellers(payment) {
      const sellerId = payment.PosSession?.id || payment.Ticket[0]?.Seller?.id;
      if (!sellerId) return true;
      return !this.filterOption.sellers.some(({ id }) => id === sellerId);
    },
    filterCourtesy(payment) {
      return payment.paymentMethod !== "COURTESY";
    },
    onProgress(progress) {
      console.log(progress);
    },
  },

  computed: {
    selectedReport() {
      return this.reportTypes.find(
        (report) => report.id === this.selectedReportId
      );
    },
  },
  mounted() {
    this.$root.$on("exportReport", this.open);
  },
  watch: {
    step(val) {
      if (val === 2) {
        this.loadReport();
        this.filterOption = {};
        this.validFilter = true;
      } else if (val === 3) {
        this.filteredData = this.filteredReports();
        // this.$refs.html2Pdf.generatePdf();
      }
    },
  },
};
</script>

<style></style>
