<template>
  <div class="my-4">
    <v-card outlined class="pa-3">
      <h6 class="mb-2">Facebook Pixel</h6>
      <v-text-field
        v-model="fbPixelId"
        label="Facebook Pixel ID"
        outlined
        dense
        placeholder="Cole o script do Pixel"
        :rules="[(v) => !v || new RegExp(/(\d{15,16})/g).test(v) || 'ID inválido']"
        hide-details="auto"
      ></v-text-field>
    </v-card>
  </div>
</template>

<script>
import PARTY from "@/services/admin/party";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      fbPixelId: this.party?.fbPixelId,
    };
  },
  methods: {
    save() {
      return PARTY.update({
        id: this.party.id,
        organizationId: this.party.organizationId,
        fbPixelId: this.fbPixelId || null,
      });
    },
  },

  computed: {
    ...mapGetters("organization", ["selectedOrganization"]),
  },

  watch: {
    party: {
      handler: function (val) {
        this.fbPixelId = val.fbPixelId;
      },
      deep: true,
    },
    fbPixelId: {
      handler: function (val) {
        if (!val) return;
        const regex = /fbq\('init', '(\d+)'\);/g;
        const id = regex.exec(val);
        if (!id) return;

        console.log(id[1]);
        if (id) this.fbPixelId = id[1];
        else this.fbPixelId = "";
      },
      immediate: true,
    },
  },
  props: {
    party: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style></style>
