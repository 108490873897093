<template>
  <div>
    <div class="d-flex justify-space-between align-center mt-2 mb-2">
      <h6 class="mb-0">Bilheteria</h6>
      <v-btn
        @click="addTicketOffice"
        color="primary"
        :small="$vuetify.breakpoint.mdAndDown"
      >
        <v-icon :left="$vuetify.breakpoint.smAndUp">mdi-plus</v-icon>
        <template v-if="$vuetify.breakpoint.smAndUp">Bilheteria</template>
      </v-btn>
    </div>

    <v-alert v-if="error" type="error" class="mt-2">
      {{ error }}
    </v-alert>

    <v-text-field
      v-model="search"
      label="Pesquisar"
      outlined
      dense
      class="mb-2"
      hide-details
    ></v-text-field>
    <v-row dense class="mx-0">
      <v-col
        v-for="session in filteredPos"
        :key="session.id"
        cols="12"
        md="6"
        lg="4"
      >
        <v-card
          class="pa-3 h-full d-flex flex-column justify-space-between"
          rounded="lg"
          outlined
        >
          <div class="d-flex gap-2">
            <v-img
              v-if="session.Device.Model?.image"
              :src="
                (session.Device?.acquirer &&
                  session.Device?.Model?.imageVariants?.[
                    session?.Device?.acquirer
                  ]) ||
                session.Device.Model.image
              "
              max-height="60"
              max-width="60"
            >
              <v-tooltip top v-if="!session.active">
                <template v-slot:activator="{ on }">
                  <v-chip v-on="on" small class="pa-1" color="warning">
                    <v-icon small color="white"> mdi-connection </v-icon>
                  </v-chip>
                </template>
                Desconectado
              </v-tooltip>
            </v-img>
            <v-icon v-else color="primary" size="60">
              mdi-cash-register
            </v-icon>
            <div class="d-flex flex-grow-1 flex-column justify-center">
              <div class="d-flex">
                <div style="display: grid" class="flex-grow-1">
                  <h6 class="mb-0 text-truncate-2-lines">
                    {{ session.name || session.Address?.name }}
                  </h6>
                </div>
                <v-btn
                  v-if="session.active"
                  text
                  @click="editPos(session)"
                  small
                >
                  <v-icon small left>mdi-pencil</v-icon>
                  Editar
                </v-btn>
              </div>
              <span>SN: {{ session.Device.serialNumber }}</span>
            </div>
          </div>
          <div class="d-flex gap-2 mt-1">
            <v-btn
              color="primary"
              small
              style="flex: 1"
              @click="posReport(session)"
            >
              <v-icon left small>mdi-cash-register</v-icon>
              Relatório
            </v-btn>
            <v-btn
              color="primary"
              small
              style="flex: 1"
              @click="cashoutPos(session)"
            >
              <v-icon left small>mdi-cash-refund</v-icon>
              Sangria
            </v-btn>
          </div>
        </v-card>
      </v-col>
    </v-row>

    <ticket-office-modal
      @refresh="getTicketOffices"
      :party="party"
      :ticketGroups="ticketGroups"
    />
    <pos-report @success="getTicketOffices" />
    <cashout-pos @success="getTicketOffices" />
    <seller-modal
      @success="getTicketOffices"
      :party="party"
      :ticketGroups="ticketGroups"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import TicketOfficeModal from "./modal/TicketOfficeModal.vue";
import TICKET from "@/services/admin/ticket";
import POS from "@/services/admin/pos";
import PosReport from "../pos/modal/PosReport.vue";
import CashoutPos from "../pos/modal/CashoutPos.vue";
import SellerModal from "./modal/SellerModal.vue";

export default {
  components: { TicketOfficeModal, CashoutPos, PosReport, SellerModal },
  data: () => ({
    loading: false,
    error: null,
    search: "",
    sessions: [],
    ticketGroups: [],
  }),
  methods: {
    addTicketOffice() {
      this.$root.$emit("ticket-office-modal");
    },

    async getTicketOffices() {
      try {
        const response = await POS.getAll(this.party.organizationId, {
          partyId: this.party.id,
          includeDisconnected: true,
        });
        this.sessions = response.sessions;
      } catch (e) {
        this.ticketGroups = false;
      }
    },
    async getTicketGroups() {
      try {
        const response = await TICKET.getTickets(
          this.party.organizationId,
          this.party.id
        );
        this.ticketGroups = response.ticketGroups;
      } catch (e) {
        this.ticketGroups = false;
      }
    },
    posReport(pos) {
      this.$emit("pos-report-modal", pos);
    },
    editPos(pos) {
      this.$emit("seller-modal", {
        ...pos,
        type: "pos",
      });
    },
    cashoutPos(pos) {
      this.$emit("cashout-pos-modal", pos);
    },
  },
  computed: {
    ...mapGetters("organization", ["selectedOrganization"]),
    filteredPos() {
      const search = this.search.toLowerCase();
      return this.sessions
        .filter((session) => {
          const name = session.name || session.Address?.name || "";
          if (name.toLowerCase().includes(search)) return true;
          if (session.Device.serialNumber.toLowerCase().includes(search))
            return true;

          return false;
        })
        .sort((a, b) => {
          if (a.active && !b.active) return -1;
          if (!a.active && b.active) return 1;
          return a.name.localeCompare(b.name);
        });
    },
  },
  async mounted() {
    await this.getTicketOffices();
    this.getTicketGroups();
  },
  props: {
    party: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style></style>
