var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{attrs:{"outlined":"","elevation":"3","rounded":"lg"}},[_c('v-progress-linear',{attrs:{"color":`blue-grey ${_vm.$vuetify.theme.dark ? 'darken-4' : 'lighten-4'}`,"background-color":`${_vm.progressColor} lighten-1`,"value":((_vm.ticketBlock.quantity - _vm.ticketBlock.tickets.remaining) /
        _vm.ticketBlock.quantity) *
      100,"height":"20"}},[_c('div',{staticClass:"d-flex caption ml-2 justify-space-around w-full"},[_c('b',[_vm._v(_vm._s(_vm.ticketBlock.quantity)+" Total")]),(_vm.ticketBlock.tickets.remaining > 0)?_c('b',[_vm._v(" "+_vm._s(_vm.ticketBlock.tickets.remaining)+" Disponíve"+_vm._s(_vm.ticketBlock.tickets.remaining != 1 ? "is" : "l")+" ")]):_c('b',[_vm._v(" Esgotado")])])]),_c('div',{staticClass:"px-4 pb-3 pt-2"},[_c('div',{staticClass:"mb-4"},[(_vm.party.active)?_c('div',{staticClass:"float-right"},[(_vm.config.opMode)?_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":_vm.copy}},[_c('v-icon',{attrs:{"x-small":""}},[_vm._v("mdi-content-copy")])],1):_vm._e(),_c('v-btn',{attrs:{"text":"","small":""},on:{"click":function($event){return _vm.addBlock(_vm.ticketBlock)}}},[_c('v-icon',{attrs:{"x-small":"","left":""}},[_vm._v("mdi-pencil")]),_vm._v(" Lote ")],1)],1):_vm._e(),_c('div',[(_vm.config.opMode)?_c('span',{staticClass:"text-overline lh-1"},[_vm._v(" #"+_vm._s(_vm._f("shortId")(_vm.ticketBlock.id))+" ")]):_vm._e(),_c('h6',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.ticketBlock.name)+" ")]),_c('v-tooltip',{attrs:{"top":"","color":"black","disabled":!_vm.membershipPrices.length},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('h5',_vm._g({staticClass:"mb-0 font-weight-medium"},on),[_vm._v(" "+_vm._s(_vm._f("currency")(_vm.ticketBlock.price,true))+" "),(_vm.membershipPrices.length)?[_vm._v(" | "),_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-card-account-details-star")])]:_vm._e()],2)]}}])},[_c('div',{staticClass:"d-flex flex-column gap-1"},_vm._l((_vm.membershipPrices),function(msPrice){return _c('div',{key:msPrice.membershipId,staticClass:"d-flex gap-2 align-center my-2"},[_c('v-chip',{staticClass:"text-13 font-weight-bold",style:({
                  background: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(${msPrice.membership.backgroundImg})`,
                  backgroundSize: 'cover',
                  border: `1px solid ${
                    msPrice.membership.backgroundColor || '#000'
                  }`,
                  boxShadow: `0 0 10px  ${
                    msPrice.membership.backgroundColor || 'rgba(0,0,0,.2)'
                  }`,
                }),attrs:{"color":msPrice.membership.backgroundColor,"text-color":"white","label":"","small":""}},[_vm._v(" "+_vm._s(msPrice.membership.name)+" ")]),_c('b',[_vm._v(_vm._s(_vm._f("currency")(msPrice.price,true)))])],1)}),0)])],1)]),_c('div',{staticClass:"absolute bottom d-flex align-end justify-space-between"},[_c('div',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('p',_vm._g(_vm._b({staticClass:"mb-0 lh-1"},'p',attrs,false),on),[_vm._v(" "+_vm._s(_vm.sellers.total)+" vendedor"+_vm._s(_vm.sellers.total != 1 ? "es" : "")+" ")])]}}])},[_c('p',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.sellers.users)+" Promoter"+_vm._s(_vm.sellers.users != 1 ? "s" : "")+" ")]),_c('p',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.sellers.pos)+" PDV"+_vm._s(_vm.sellers.pos != 1 ? "s" : "")+" ")])]),_c('v-chip',{staticClass:"font-weight-bold",attrs:{"x-small":"","label":"","color":_vm.status.color}},[_vm._v(" "+_vm._s(_vm.status.text)+" ")])],1),_c('div',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-icon',_vm._g({attrs:{"right":""}},on),[_vm._v(" mdi-"+_vm._s(_vm.ticketBlock.allowTransfer ? "account-switch" : "account-lock")+" ")])]}}])},[_c('span',[_vm._v(" Transferência "),_c('b',[_vm._v(" "+_vm._s(_vm.ticketBlock.allowTransfer ? "permitida" : "não permitida")+" ")])])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-icon',_vm._g({attrs:{"right":""}},on),[_vm._v(" mdi-earth"+_vm._s(_vm.ticketBlock.onlineSale ? "" : "-off")+" ")])]}}])},[_c('span',[_vm._v(" Venda online "),_c('b',[_vm._v(_vm._s(_vm.ticketBlock.onlineSale ? "permitida" : "não permitida"))])])])],1)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }